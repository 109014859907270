
  import {
    defineComponent,
    onMounted,
    onBeforeUnmount,
    ref,
    computed,
    reactive,
    nextTick,
  } from "vue";
  import Header from "@/components/header/index.vue";
  import Tail from "@/components/tail/index.vue";
  
  const cbbc = require("../assets/imgs/pay.png");
  const mining = require("../assets/imgs/globpay.png");
  const app = require("../assets/imgs/bank.png");
  
  export default defineComponent({
    name: "Home",
    components: { Header, Tail },
    setup() {
      const bkcolor = ref("white");
      const hide = ref(true);
      const data = reactive({
        app,
        cbbc,
        mining,
      });
      const winScroll = () => {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  
        if (scrollTop > 80) {
          bkcolor.value = "white";
        } else {
          bkcolor.value = "white";
        }
        if (scrollTop >= 2200) {
          showapp.value = false;
        } else {
          showapp.value = true;
        }
        if (scrollTop >= 2400 && scrollTop <= 2790) {
          showcbdc.value = true;
        } else {
          showcbdc.value = false;
        }
        if (scrollTop >= 2900) {
          showmin.value = true;
        } else {
          showmin.value = false;
        }
      };
      onMounted(() => {
        window.addEventListener("scroll", winScroll);
      });
      onBeforeUnmount(() => {
        window.removeEventListener("scroll", winScroll);
      });
      const showapp = ref(true);
      const showcbdc = ref(false);
      const showmin = ref(false);
      const showimg = computed(() => {
        return showapp.value || showcbdc.value || showmin.value;
      });
      const closeme = () => {
        hide.value = true;
      }
      const showme = () => {
        hide.value = false;
      }
      const stop = (ev:any) => {
        ev?.stopPropagation()
      }
      return { stop,showme,hide,closeme,data, showimg, bkcolor, showapp, showcbdc, showmin };
    },
  });
  