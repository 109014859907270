
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { resizeBus } from "@/helper";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const data = reactive({
      app: [true, false, false, false, false, false, false],
    });
    const winScroll = () => {
      const dom: any = block01.value;
      const top = dom.getBoundingClientRect().top;
      const het = dom.getBoundingClientRect().height;
      data.app = [false, false, false, false, false, false, false];
      if (top > 100) {
        data.app[0] = true;
      } else if (top > -het) {
        data.app[1] = true;
      } else if (top > -het * 2) {
        data.app[2] = true;
      } else if (top > -het * 3) {
        data.app[3] = true;
      } else if (top > -het * 4) {
        data.app[4] = true;
      } else if (top > -het * 5) {
        data.app[5] = true;
      } else {
        data.app[6] = true;
      }
    };
    onMounted(() => {
      resizeBus.add("app", () => {
        clientH.value = window.document.documentElement.clientHeight;
      });
      window.addEventListener("scroll", winScroll);
    });
    onBeforeUnmount(() => {
      resizeBus.remove("app");
      window.removeEventListener("scroll", winScroll);
    });
    const showapp = ref(true);
    const showcbdc = ref(false);
    const showmin = ref(false);
    const showimg = computed(() => {
      return showapp.value || showcbdc.value || showmin.value;
    });
    const gotostore = (ios = false) => {
      if (ios) {
        window.location.href = "https://apps.apple.com/us/app/roxe/id1585752345";
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.roxe.pay";
      }
    };
    const block01 = ref(null);
    const clientH = ref(0);
    clientH.value = document.documentElement.clientHeight;
    const divt = computed(() => {
      if (clientH.value > 600) {
        return (clientH.value - 600) / 2;
      }
      return 0;
    });
    return {
      divt,
      block01,
      gotostore,
      data,
      showimg,
      bkcolor,
      showapp,
      showcbdc,
      showmin,
    };
  },
});
